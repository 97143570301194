import React from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "../image";

class TeaserGrid extends React.Component {
  render() {
    const { items } = this.props;

    return (
      <Container className="teaser-grid">
        <Row>
          {items &&
            items.map((item, index) => {
              const { title, image, desc } = item;
              return (
                <Col
                  xs={12}
                  sm={4}
                  key={`teaser-grid-col-${index}`}
                  className="teaser-grid-item"
                >
                  <Image
                    isBackgroundImage
                    url={image}
                    width={120}
                    height={120}
                    alt={`${title} image`}
                  />
                  <p className="title">{title}</p>
                  <p>{desc}</p>
                </Col>
              );
            })}
        </Row>
      </Container>
    );
  }
}

TeaserGrid.propTypes = {
  items: PropTypes.array,
};

export default TeaserGrid;
