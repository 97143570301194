/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import { useInView } from "react-intersection-observer";
import { useTranslation } from 'next-i18next';

export default function InstagramSlider(props) {
  const { posts } = props;
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  /* Sometimes the script is loaded before the <blockqoute> is rendered */
  const igEmbd = typeof window !== "undefined" && window?.instgrm?.Embeds;
  React.useEffect(() => {
    if (inView && posts?.length > 0) {
      if (igEmbd) {
        igEmbd.process();
      } else {
        const s = document.createElement("script");
        s.async = true;
        s.defer = true;
        s.src = `//www.instagram.com/embed.js`;
        s.id = "react-instagram-embed-script";
        const { body } = document;
        if (body) {
          body.appendChild(s);
        }
      }
    }
  }, [posts, inView, igEmbd]);

  return (
    <div className="instagram-slider" ref={ref}>
      <div className="instagram-slider-header">
        <span className="divider" />
        <span className="text">
          {t("homepage:sectionTitle.instagramSlider")}
        </span>
        <span className="divider" />
      </div>
      {inView && (
        <Container className="instagram-slider-container">
          {posts
          .filter((s, index) => index <= 10)
          .map((s, index) => (
            <blockquote
              key={`instgram-${index}`}
              dangerouslySetInnerHTML={{ __html: s }}
            />
          ))}
        </Container>
      )}
    </div>
  );
}

InstagramSlider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  posts: PropTypes.array,
};

InstagramSlider.defaultProps = {
  posts: [],
};
