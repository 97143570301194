import dynamic from "next/dynamic";
import { useMemo } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import EventCard from "../card";

const InFeedAd = dynamic(() => import("../GoogleAd/inFeedAd"));

export interface Props {
  events?: any[];
  type?: "card" | "longCard" | "horizontalCard";
  gaClickLabel?: string;
  showAd?: boolean;
}
export default function CardGrid({
  events,
  type = "card",
  gaClickLabel,
  showAd: _showAd,
}: Props) {
  const { width } = useWindowDimensions();
  const showAd = width > 0 && width < 600 && _showAd;
  let cardType: "normal" | "long" | "horizontal" = "normal";
  if (type === "longCard") {
    cardType = "long";
  } else if (type === "horizontalCard") {
    cardType = "horizontal";
  }

  const Items = useMemo(() => {
    let result: JSX.Element[] = [];
    if (events?.length > 0) {
      result = events.map((eventData, index) => (
        <Col
          key={`${eventData.uid}-${index}`}
          xs={12}
          sm={6}
          md={cardType === "horizontal" ? 6 : 4}
        >
          <EventCard
            eventData={eventData}
            cardType={cardType}
            gaClickLabel={gaClickLabel}
          />
        </Col>
      ));
    }
    if (showAd) {
      for (let i = 6; i <= result.length; i += 6) {
        const key = `grid-ad-${i}`;
        const item = (
          <Col key={key} xs={12} sm={6} md={cardType === "horizontal" ? 6 : 4}>
            <InFeedAd style={{ marginBottom: "12px" }} id={`${key}-infeed`} />
          </Col>
        );
        result.splice(i, 0, item);
      }
    }
    return result;
  }, [showAd, events, cardType]);

  if (!events) {
    return <div />;
  }

  return (
    <Container className="card-grid">
      <Row>{Items}</Row>
    </Container>
  );
}
