import NextImage from "next/image";

function CompanySlider() {
  const paths = [
    "/images/company/1.png",
    "/images/company/12.png",
    "/images/company/15.jpg",
    "/images/company/19.png",
    "/images/company/21.png",
    "/images/company/4.jpg",
    "/images/company/7.jpg",
    "/images/company/10.jpg",
    "/images/company/13.png",
    "/images/company/16.jpg",
    "/images/company/2.jpg",
    "/images/company/22.png",
    "/images/company/5.jpg",
    "/images/company/8.jpg",
    "/images/company/11.jpg",
    "/images/company/14.jpg",
    "/images/company/18.jpg",
    "/images/company/20.jpg",
    "/images/company/3.jpg",
    "/images/company/6.jpg",
    "/images/company/9.jpg",
  ];
  return (
    <div className="company-slider">
      <div className="company-slider-wrapper">
        {paths.map((p) => (
          <NextImage
            key={`item-${p}`}
            className="company-slider-img"
            src={p}
            width={200}
            height={140}
            alt=""
            // layout={"fill" as any}
            objectFit="contain"
            objectPosition="center"
            quality={40}
          />
        ))}
      </div>
    </div>
  );
}

export default CompanySlider;
