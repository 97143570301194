import { withAuthUser } from "next-firebase-auth";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React from "react";
import ButtonGrid from "../components/buttonGrid";
import CardGrid from "../components/cardGrid";
import CompanySlider from "../components/companySlider";
import FeatureImageGrid from "../components/featureImageGrid";
import InstagramSlider from "../components/instagramSlider";
import Layout from "../components/layout";
import ParallaxSearch from "../components/parallaxSearch";
import Section from "../components/section";
import WeekGrid from "../components/weekGrid";
import WhyPlaywhat from "../components/whyPlaywhat";
import {
  getArticleEvents,
  getExclusiveEvent, getNewestEvents, getRecommendedEvents, getWeekendEvents, getWeeklyEvents
} from "../services/event";
import { viewHomepage } from "../services/gtagForRecommendationAi";
import { getMyInstagramEmbededPosts } from "../services/instagram";
import { getQuickFilter } from "../services/scheduledConfig";
import { getBuildVersion } from "../utilities/common";
import { PageProps } from "../types/page";

interface Props extends PageProps {
  newestEvents: any[];
  exclusiveEvent: any;
  recommendedEvents: any[];
  weeklyEvents: any[];
  // weekendEvents: any[];
  articleEvents: any[];
  featureCategoryIdList: any[];
  buildVersion: string;
  quickFilter: string;
  myInstagramPosts: any;
}

function HomePage({
  newestEvents,
  exclusiveEvent,
  weeklyEvents,
  recommendedEvents,
  articleEvents,
  featureCategoryIdList,
  buildVersion,
  quickFilter,
  myInstagramPosts,
}: Props) {
  const { t } = useTranslation("common");

  React.useEffect(() => {
    viewHomepage();
  }, []);

  const title = t("siteTitle");
  return (
    <Layout isHomepage buildVersion={buildVersion} title={title}>
      <ParallaxSearch
        recommendedEvent={exclusiveEvent}
        quickFilter={quickFilter}
      />
      {recommendedEvents && recommendedEvents.length > 0 && (
        <Section
          title={t("homepage:sectionTitle.recommendedEvent")}
          showMoreLink="/events"
          gaShowMoreClickLabel="homepage_recommended_event_show_more"
        >
          <FeatureImageGrid
            items={recommendedEvents.map((e) => {
              const { slug, uid } = e;
              const url = `/events/${slug || uid}`;
              return {
                title: e.title,
                imageUrl: e.logo_url,
                url,
                gaTrackingProps: {
                  "data-click-label": "homepage_recommended_event",
                  "data-click-value": uid,
                },
              };
            })}
          />
        </Section>
      )}
      {articleEvents && articleEvents.length > 0 && (
        <Section
          title={t("homepage:sectionTitle.eventArticle")}
          showMoreLink="/articles"
          gaShowMoreClickLabel="homepage_article_show_more"
        >
          <CardGrid
            events={articleEvents}
            type="horizontalCard"
            gaClickLabel="homepage_article"
            showAd
          />
        </Section>
      )}
      {weeklyEvents && weeklyEvents.length > 0 && (
        <Section
          title={t("homepage:sectionTitle.weeklyEvent")}
          showMoreLink="/events/this-week"
          gaShowMoreClickLabel="homepage_weekly_event_show_more"
        >
          {/* <WeekGrid events={weeklyEvents} /> */}
          <CardGrid
            events={weeklyEvents}
            gaClickLabel="homepage_weekly_event"
            showAd
          />
        </Section>
      )}
      {/* {weekendEvents && weekendEvents.length > 0 && (
        <Section
          title={t("homepage:sectionTitle.weekendEvent")}
          showMoreLink="/events/this-week"
          gaShowMoreClickLabel="homepage_weekend_event_show_more"
        >
          <CardGrid
            events={weekendEvents}
            gaClickLabel="homepage_weekend_event"
            showAd
          />
        </Section>
      )} */}
      {myInstagramPosts &&
        myInstagramPosts.result &&
        myInstagramPosts.result.length > 0 && (
          <Section isFluid>
            <InstagramSlider posts={myInstagramPosts.result} />
          </Section>
        )}
      {/* TODO: Today/Last week events */}
      {featureCategoryIdList && featureCategoryIdList.length > 0 && (
        <Section>
          <ButtonGrid />
        </Section>
      )}
      {newestEvents && newestEvents.length > 0 && (
        <Section
          title={t("homepage:sectionTitle.newestEvent")}
          showMoreLink="/events"
          gaShowMoreClickLabel="homepage_newest_event_show_more"
        >
          <CardGrid
            events={newestEvents}
            gaClickLabel="homepage_newest_event"
            showAd
          />
        </Section>
      )}
      <Section isFluid>
        <CompanySlider />
      </Section>
      <WhyPlaywhat />
    </Layout>
  );
}

export async function getStaticProps(ctx) {
  const { locale } = ctx;
  const ssrConfig = await serverSideTranslations(locale, [
    "common",
    "homepage",
  ]);
  const lang = locale;
  const newestEvents = await getNewestEvents({ lang, size: 6 });
  const exclusiveEvent = await getExclusiveEvent({ lang });
  const recommendedEvents = await getRecommendedEvents({ lang, size: 12 });

  let weeklyEvents = await getWeeklyEvents({ lang, size: 100 });
  /* TODO: move this logic to backend? */
  weeklyEvents = weeklyEvents.filter((e) => !['Hkpl', 'EventHK'].includes(e.platform_source)).slice(0, 12)
  // const weekendEvents = await getWeekendEvents({ lang, size: 12 });
  const articleEvents = await getArticleEvents({ lang, size: 14 });
  const buildVersion = getBuildVersion();
  // eslint-disable-next-line global-require
  const featureCategoryIdList = require("../public/mapping/featured-category.json");
  const quickFilter = await getQuickFilter({ lang });
  const myInstagramPosts = await getMyInstagramEmbededPosts();
  return {
    props: {
      ...ssrConfig,
      newestEvents,
      exclusiveEvent,
      recommendedEvents,
      weeklyEvents,
      // weekendEvents,
      articleEvents,
      featureCategoryIdList,
      buildVersion,
      quickFilter,
      myInstagramPosts,
    },
    // we will attempt to re-generate the page:
    revalidate: 60, // In seconds
  };
}

export default withAuthUser<Props>()(HomePage);
