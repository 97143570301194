import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { getExcerpt } from "../../utilities/common";
import Image from "../image";
import BackgroundImage from "../../public/images/background/bg6.jpg";
import BlurDataUrlJson from "../../public/images/blurDataUrl.json";

interface Props {
  recommendedEvent: any;
  quickFilter: string;
}

export default function ParallaxSearch(props: Props) {
  const [name, setName] = React.useState<string>("");
  const router = useRouter();
  const { t } = useTranslation();
  const { quickFilter, recommendedEvent } = props;
  const tags = ((quickFilter || "").split(",") || []).filter((tag) => !!tag);
  const eventId = recommendedEvent.slug || recommendedEvent.uid;

  const onKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.charCode === 13) {
      submit();
      event.preventDefault();
    }
  };

  const submit = () => {
    const encodedName = encodeURIComponent(name);
    router.push(`/events?q=${encodedName}`);
  };

  let content = recommendedEvent.excerpt;
  if (!content) {
    content = getExcerpt(recommendedEvent.desc);
  }
  return (
    <div className="parallax-search">
      <Image
        className="parallax-search-background-image"
        isBackgroundImage
        url={BackgroundImage}
        blurDataURL={BlurDataUrlJson.bg6.data}
        placeholder="blur"
        alt=""
      />
      {/* <div className="credit-to">
      Credit to <a href="https://snappy.collaction.hk/" rel="nofollow noreferrer" target="_blank">Snappy</a>
    </div> */}
      <div className="parallax-search-container">
        <Container>
          <Row>
            <Col xs={12} sm={6} />
            <Col xs={12} sm={6}>
              {recommendedEvent?.title && (
                <div className="recommend-event-box">
                  <span className="recommend-event-box-title-prefix">
                    {t("parallaxSearch.recommendedEventHelptext")}
                  </span>
                  <br />
                  <div className="recommend-event-box-event-container">
                    <div className="recommend-event-box-event-container-image">
                      {recommendedEvent?.logo_url && (
                        <Image
                          className="parallax-event-image"
                          isBackgroundImage
                          url={recommendedEvent.logo_url}
                          alt="event photo"
                          blurDataURL={BlurDataUrlJson.grey.data}
                          placeholder="blur"
                        />
                      )}
                    </div>
                    <div className="recommend-event-box-event-container-detail">
                      <Link href="/events/[id]" as={`/events/${eventId}`}>
                        <a
                          className="recommend-event-box-title"
                          data-click-label="homepage_exclusive_event"
                          data-click-value={eventId}
                        >
                          {recommendedEvent.title}
                        </a>
                      </Link>
                      <p className="recommend-event-box-description">
                        {content}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <Form>
                <InputGroup>
                  <Form.Control
                    placeholder={t("parallaxSearch.placeholder")}
                    aria-label="Search events"
                    size="lg"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    onKeyPress={onKeyPress}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="success"
                      size="lg"
                      onClick={submit}
                      className="search-button"
                      aria-label="search"
                      data-click-label="cta_homepage_quick_search"
                    >
                      <Image
                        className="svg-icon"
                        width={18}
                        height={18}
                        url="/icons/search-outline.svg"
                        alt=""
                      />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
              {tags?.length > 0 && (
                <div className="quick-filter">
                  <span className="quick-filter-label">
                    {t("parallaxSearch.quickTagLabel")}
                  </span>
                  {tags
                  .map((tag) => (
                    <Link
                      key={`tag-${tag}`}
                      href={{
                        pathname: "/events",
                        query: { q: encodeURIComponent(tag) },
                      }}
                    >
                      <a
                        className="quick-filter-tag"
                        data-click-label="homepage_quick_filter"
                        data-click-value={tag}
                      >
                        {tag}
                      </a>
                    </Link>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
