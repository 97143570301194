import Link from "next/link";
import Container from "react-bootstrap/Container";
import Image from "../image";

export interface Item {
  title: string;
  imageUrl: string;
  url: string;
  gaTrackingProps?: {
    "data-click-label": string;
    "data-click-value": string;
  };
}

export interface Props {
  items: Item[];
}
export default function featureImageGrid({ items }: Props) {
  if (items?.length === 0) {
    return <div />;
  }

  return (
    <Container className="card-grid feature-image-grid">
      <div className="feature-image-grid-row">
        {items.map((item, index) => (
          <Link href={item.url} key={`f-${index}`}>
            <a className="feature-image-grid-container" {...(item.gaTrackingProps || {})}>
              <Image
                className="feature-image"
                isBackgroundImage
                url={item.imageUrl}
                alt="event photo"
              />
              <div className="title">{item.title}</div>
            </a>
          </Link>
        ))}
      </div>
    </Container>
  );
}
