import { useTranslation } from "next-i18next";
import Section from "../section";
import TeaserGrid from "../teaserGrid";
import SearchImage from "../../public/images/whyPlaywhat/search.png";
import CreateImage from "../../public/images/whyPlaywhat/create.png";
import PromoteImage from "../../public/images/whyPlaywhat/promote.png";

function getImage(s: string) {
  switch (s) {
    case 'search':
      return SearchImage;
    case 'create':
      return CreateImage;
    default:
      return PromoteImage;
  }
}

export default function WhyPlaywhat() {
  const { t } = useTranslation("common");
  const teaserKeys = ["search", "create", "promote"];
  const teaserItems = teaserKeys.map((key) => ({
    title: t(`homepage:whyPlaywhat.${key}.title`),
    image: getImage(key),
    desc: t(`homepage:whyPlaywhat.${key}.desc`),
  }));

  return (
    <Section title={t("homepage:sectionTitle.whyPlaywhat")}>
      <TeaserGrid items={teaserItems} />
    </Section>
  );
}
