import Link from "next/link";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NextImage from "next/image";
import { useTranslation } from 'next-i18next';
import OnlineImage from '../../public/images/online.png';
import TourImage from '../../public/images/tour.png';
import WorkshopImage from '../../public/images/workshop.png';
import VolunteeringImage from '../../public/images/volunteering.png';
import ArtsImage from '../../public/images/arts.png';
import SportsImage from '../../public/images/sports.png';

const categories = ["online", "tour", "workshop", "volunteering", "arts", "sports"];

function getImage (c: string) {
  switch (c) {
    case 'online':
      return OnlineImage;
    case 'tour':
      return TourImage;
    case 'workshop':
      return WorkshopImage;
    case 'volunteering':
      return VolunteeringImage;
    case 'arts':
      return ArtsImage;
    case 'sports':
      return SportsImage; 
    default:
      return OnlineImage;
  }
}

export default function ButtonGrid() {
  const { t } = useTranslation();

  const buttons = (categories || []).map((c) => ({
    label: t(`category.${c}`),
    value: c,
    // src: `/images/${c}.png`,
    src: getImage(c),
  }));
  return (
    <Container className="button-grid">
      <Row>
        {buttons &&
          buttons.map((item) => {
            const { label, src, value } = item;
            return (
              <Col xs={4} sm={2} key={`button-grid-col-${value}`}>
                <Link
                  href={`/events?c=${value}`}
                >
                  <a className="button-grid-item" data-click-label="cta_homepage_categories" data-click-value={value}>
                    <NextImage
                      src={src}
                      objectFit="contain"
                      objectPosition="center"
                      // layout="fill"
                      alt={`${label} image`}
                      // placeholder="blur"
                      width={100}
                      height={100}
                    />
                    <p>{label}</p>
                  </a>
                </Link>
              </Col>
            );
          })}
      </Row>
    </Container>
  );
}