import { useTranslation } from "next-i18next";
import Link from "next/link";
import Container from "react-bootstrap/Container";
import SectionTitle from "./title";

interface Props {
  title?: string;
  showMoreLink?: string;
  gaShowMoreClickLabel?: string;
  isFluid?: boolean;
}

export default function Section(props: React.PropsWithChildren<Props>) {
  const {
    title,
    showMoreLink,
    children,
    isFluid,
    gaShowMoreClickLabel,
  } = props;
  const { t } = useTranslation();
  return (
    <Container className={`layout-section ${isFluid ? "fluid" : ""}`}>
      {title && <SectionTitle text={title} />}
      {children}
      {showMoreLink && (
        <Link href={`${showMoreLink}`}>
          <a
            className="button button-action"
            data-click-label={gaShowMoreClickLabel}
          >
            {t("showMoreEvents")}
          </a>
        </Link>
      )}
    </Container>
  );
}
