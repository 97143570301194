import React from 'react';
import PropTypes from 'prop-types';

export default class SectionTitle extends React.Component {
  render() {
    const { text } = this.props;
    return (
      <div className="section-title">
        <h2>{text}</h2>
        <span className="separator" />
      </div>
    );
  }
}

SectionTitle.propTypes = {
  text: PropTypes.string
}